import { GET_PACKS, GET_SUGGESTIONS, GET_ORDER, RELEASE_ORDER, GET_BOXES, LOGOUT_USER, SET_LANG, SET_SEARCHING, SET_PROCESSING, SET_LAST_SCANNED, SET_FILTERS, API_ERROR} from '../constants/ActionTypes'
import { push } from 'connected-react-router'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import Odoo from '../utils/Odoo'
import { setQueries } from '../utils/Tools'

function api_error(dispatch, e){
  if (e && e.status === 403){
    Popup.alert(intl.get('Sesja wygasła'))
    dispatch({
      type: LOGOUT_USER
    })
  } else {
    dispatch({
      type: API_ERROR,
      message: e.message
    })
  }
}

export function selectLang(lang) {
  const query = setQueries({
    lang: lang
  })
  return dispatch => {
    dispatch({
      type: SET_LANG,
      payload: lang
    })
    dispatch(push({
      search: query
    }))
  }
}

export function getPacks() {
  return dispatch => {
    Odoo.call('packing_zone/get_packages').then(res => {
      dispatch({
        type: GET_PACKS,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function resetSuggestions() {
  return dispatch => {
    dispatch({
      type: GET_SUGGESTIONS,
      payload: false
    })
  }
}

export function setFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_FILTERS,
      payload: filters
    })
  }
}

function no_orders_left(dispatch, pack) {
  Popup.create({
    content: intl.get('W tej paczce nie ma już zamówień'),
    buttons: {
      right: [{
        text: intl.get('Wybierz inną paczkę'),
        className: 'ok',
        key: 'enter',
        action: function () {
          Popup.close();
          dispatch(selectPack());
        }
      }]
    }
  });
}

export function getSuggestions(pack, key) {
  let params = { package_id: pack }
  let method = false
  switch (key) {
    case 'name':
      method = 'packing_zone/get_suggestions_by_product_name'
      break
    case 'client_login':
      method = 'packing_zone/get_suggestions_by_client_login'
      break
    case 'sale_order':
      method = 'packing_zone/get_suggestions_by_sale_order'
      break
    default:
      method = 'packing_zone/get_suggestions_by_product_code'
  }
  return dispatch => {
    Odoo.call(method, params).then(res => {
      if((res && res.products && res.products.length === 0) && (res && res.bundles && res.bundles.length === 0) && pack !== -1){
        no_orders_left(dispatch)
      }
      dispatch({
        type: GET_SUGGESTIONS,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function getBoxes(pack) {
  let params = {
    package_id: pack
  }
  return dispatch => {
    Odoo.call('packing_zone/get_multipack', params).then(res => {
      if(res.every((box) => {
        return box.to_process === false
      })){
        no_orders_left(dispatch)
      }
      dispatch({
        type: GET_BOXES,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function releaseOrder(order_id) {
  let params = {
    order_id
  }
  return dispatch => {
    Odoo.call('packing_zone/release_order', params).then(res => {
      dispatch({
        type: RELEASE_ORDER,
        payload: res
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function getOrder(kod, order_id, pack, confirmed, get_order_by) {
  let params = {
    order_id,
    code: kod,
    package_id: pack,
    confirmed,
    get_order_by
  }
  return (dispatch, getState) => {
    const { config } = getState();
    const { auto_open_next_order } = config;

    dispatch({
      type: SET_SEARCHING,
      payload: true
    })
    dispatch({
      type: SET_LAST_SCANNED,
      payload: kod
    })
    Odoo.call('packing_zone/get_order_data', params).then(res => {
      if (res.already_scannned) {
        if (auto_open_next_order) {
          dispatch(getOrder(kod, res.already_scannned, pack, true, get_order_by));
        } else {
        Popup.create({
          content: intl.get('Istnieje prawdopodobieństwo, że to zamówienie jest otwarte na innej pakowalni. \nNa pewno chcesz przejść do zamówienia?'),
          buttons: {
            right: [{
              text: intl.get('Tak'),
              action: function () {
                Popup.close()
                dispatch(getOrder(kod, res.already_scannned, pack, true, get_order_by))
              }
            },
            {
              text: intl.get('Anuluj'),
              className: 'cancel-button',
              action: function () {
                Popup.close()
                dispatch(selectOrder())
              }
            }]
          }
        })
      }
      } else {
        dispatch({
          type: GET_ORDER,
          payload: res
        })
        if(res && res.id){
            if (get_order_by === "sale_order_barcode") {
               const query = setQueries({
               pack: res.sale_order_package_id
               })
            dispatch(push({
              search: query
            }))
            }
          dispatch(selectOrder(res.id))
        }
      }
    }).catch(error => {
      api_error(dispatch, error)
    }).then(() => {
        dispatch({
          type: SET_SEARCHING,
          payload: false
        })
    })
  }
}

export function getMultiSearch(searchData) {
  console.log(searchData);
  return dispatch => {
    dispatch({
      type: SET_PROCESSING,
      payload: true
    });
    Odoo.call('packing_zone/get_data_from_multi_search', searchData)
      .then(res => {
        dispatch({
          type: SET_PROCESSING,
          payload: false
        });
        if (res) {
          console.log(res);
          if (res.is_order) {
            dispatch(getOrder(res.id, res.sale_order_package_id, true, "sale_order_barcode"));
          } else {
            dispatch(selectPack(res.id, res.multipack));
          }
        }
      })
      .catch(error => {
        dispatch({
          type: SET_PROCESSING,
          payload: false
        });
        dispatch({
          type: API_ERROR,
          message: error.message
        });
      });
  };
}

export function getCart(barcode) {
  let params = {
    barcode
  }
  return dispatch => {
    dispatch({
      type: SET_SEARCHING,
      payload: true
    })
    Odoo.call('packing_zone/get_cart', params).then(res => {
      if(res){
        const query = setQueries({
          pack: res.id,
          multi: res.multipack?'1':undefined
        })
        dispatch(push({
          search: query
        }))
      } else {
        Popup.alert(intl.get('Nie znaleziono wózka!'))
      }
    }).catch(error => {
      api_error(dispatch, error)
    }).then(() => {
        dispatch({
          type: SET_SEARCHING,
          payload: false
        })
    })
  }
}

export function selectPack(pack, multi) {
  const query = setQueries({
    pack,
    multi: multi?'1':undefined
  })
  return dispatch => {
    dispatch(push({
      search: query
    }))
  }
}

export function selectOrder(order) {
  const query = setQueries({
    order: order
  })
  return dispatch => {
    dispatch(push({
      search: query
    }))
  }
}


export function selectOrderWithCleanedDone(order) {
  return (dispatch, getState) => {
    const state = getState();
    const currentSearch = state.router.location.search;
    const withoutDone = currentSearch.replace(/[\?&]done/g, '');

    const query = new URLSearchParams(withoutDone);
    query.set('order', order);

    dispatch(push({
      search: query.toString()
    }));

    window.location.reload();
  };
}

export function confirmDone() {
  const query = setQueries({
    order: undefined,
    done: undefined
  })
  return dispatch => {
    dispatch(push({
      search: query
    }))
  }
}

export function returnToHome() {
  const query = setQueries({
    order: undefined,
    done: undefined,
    pack: undefined
  })
  return dispatch => {
    dispatch(push({
      search: query
    }))
  }
}

export function finishOrder(data) {
  let params = {data}
  return dispatch => {
    dispatch({
      type: SET_PROCESSING,
      payload: true
    })
    Odoo.call('packing_zone/finish_order', params).then(res => {
      dispatch({
        type: SET_PROCESSING,
        payload: false
      })
      if(res){
        const query = setQueries({
          done: 1
        })
        dispatch(push({
          search: query
        }))
      }
    }).catch(error => {
      dispatch({
        type: SET_PROCESSING,
        payload: false
      })
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}

export function sendProductShortages(data) {
  let params = { data };
  return dispatch => {
    dispatch({
      type: SET_PROCESSING,
      payload: true
    });
    Odoo.call('packing_zone/product_shortages', params).then(res => {
      dispatch({
        type: SET_PROCESSING,
        payload: false
      });
      if (res) {
        const query = setQueries({
          done: 1
        })
        dispatch(push({
          search: query
        }))
      }
    }).catch(error => {
      dispatch({
        type: SET_PROCESSING,
        payload: false
      });
      dispatch({
        type: API_ERROR,
        message: error.message
      });
    });
  };
}

export function getNextOrder(order_id) {
  let params = { 'order_id': order_id };
  return dispatch => {
    Odoo.call('packing_zone/get_next_order', params)
      .then(res => {
        if (res) {
          dispatch(selectOrderWithCleanedDone(res.id))
        } else {
          dispatch(returnToHome())
        }
      })
      .catch(error => {
        console.log(error);
        dispatch({
          type: API_ERROR,
          message: error.message
        });
      });
  };
}


export function printInvoice(order_id) {
  let params = {order_id}
  return dispatch => {
    Odoo.call('packing_zone/print_invoice', params).then(res => {
      if(res){
        Popup.alert('OK')
      }
    }).catch(error => {
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}

export function printLabelAgain(order_id) {
  let params = {order_id}
  return dispatch => {
    Odoo.call('packing_zone/print_label', params).then(res => {
      if(res){
        Popup.alert('OK')
      }
    }).catch(error => {
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}

export function printErrorLabel(order_id) {
  let params = {order_id}
  return dispatch => {
    Odoo.call('packing_zone/print_error_label', params).then(res => {
      if(res){
        Popup.alert('OK')
      }
    }).catch(error => {
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}
