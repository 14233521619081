import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getCart, getOrder, getMultiSearch } from '../actions/PackActions'
import Packs from './Packs'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  searching: state.pack.searching,
  pack: state.router.location.query.pack ? parseInt(state.router.location.query.pack) : false,
  able_scan_order_during_cart_scan: state.config.able_scan_order_during_cart_scan,
  //use_multi_search: state.config.use_multi_search,
  use_multi_search: true
})

const mapDispatchToProps = dispatch => ({
  getCartAction: (kod) => dispatch(getCart(kod)),
  getOrderAction: (kod_order, pack, find_key) => dispatch(getOrder(kod_order, false, pack, false, find_key)),
  getMultiSearchAction: (multi_search) => dispatch(getMultiSearch(multi_search)),
})

class ScanCartComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      kod: '',
      kod_order: '',
      listMode: false,
      multi_search: ''
    }
  }

  componentDidMount() {
    document.getElementById('kod').focus()
  }

  toggleMode = () => {
    const { listMode } = this.state
    this.setState({
      listMode: !listMode
    })
  }

  handleChange = (event) => {
    const {target} = event
    this.setState({
      [target.name]: target.value
    })
  }

  handleKey = (event) => {
    if (event.which === 13) {
      this.handleSubmit(event)
    }
  }

  handleOrderKey = (event) => {
    if (event.which === 13) {
      this.handleOrderSubmit(event)
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { getCartAction } = this.props
    const kod = document.getElementById('kod').value
    if (kod){
      getCartAction(kod)
    } else {
      Popup.alert(intl.get('Wprowadź kod wózka'))
    }
  }

  handleOrderSubmit = (event) => {
    event.preventDefault()
    const {pack, getOrderAction } = this.props
    const kod_order = document.getElementById('kod_order').value
    if (kod_order){
      getOrderAction(kod_order, pack, "sale_order_barcode")
    } else {
      Popup.alert(intl.get('Wprowadź zamówienie'))
    }
  }

  handleMultiSearch = (event) => {
    event.preventDefault();
    const { getMultiSearchAction } = this.props;
    const multi_search = document.getElementById('multi_search').value;
    if (multi_search) {
      const searchData = {
        code: multi_search,
        barcode: multi_search,
        get_order_by: 'sale_order_barcode'
      };
      getMultiSearchAction(searchData);
    } else {
      Popup.alert(intl.get('Wprowadź zamówienie'));
    }
  }  

  render() {
    const { searching, able_scan_order_during_cart_scan, use_multi_search } = this.props
    const { kod, kod_order, listMode, multi_search } = this.state

    const containerStyle = use_multi_search ? { display: 'none' } : {};

    if (listMode){
      return (
        <>
        <Packs/>
        <div className="buttons_wrap">
          <button className="pull-left secondary" onClick={this.toggleMode}>{intl.get('Anuluj')}</button>
        </div>
        </>
      )
    } else {
      return (
      <div>
        <div className="container" style={containerStyle}>
          <form onSubmit={this.handleSubmit}>
            <div className="ramka center">
              <h4>{intl.get('Skanowanie wózka')}</h4>
              <input
                type="text"
                value={kod}
                placeholder={intl.get('Kod wózka lub pakietu')}
                onChange={this.handleChange}
                onKeyPress={this.handleKey}
                className="searchBox"
                id="kod"
                name="kod"
                autoComplete="off"
                />
              <div className="buttons_wrap">
                <button className="pull-left" onClick={this.toggleMode}>{intl.get('Pokaż listę paczek')}</button>
                <input type="submit" className="pull-right" value={intl.get('Dalej')} disabled={searching} />
              </div>
            </div>
          </form>
        </div>
        {able_scan_order_during_cart_scan &&
          <div className="container" style={containerStyle}>
              <form onSubmit={this.handleOrderSubmit}>
                <div className="ramka center">
                  <h4>{intl.get('Wyszukiwanie zamówienia')}</h4>
                  <input
                    type="text"
                    value={kod_order}
                    placeholder={intl.get('Numer zamówienia lub listu przewozowego')}
                    onChange={this.handleChange}
                    onKeyPress={this.handleOrderKey}
                    className="searchBox"
                    id="kod_order"
                    name="kod_order"
                    autoComplete="off"
                    />
                  <div className="buttons_wrap">
                    <span className="pull-left"/>
                    <input type="submit" className="pull-right" value={intl.get('Dalej')} disabled={searching}/>
                  </div>
                </div>
              </form>
            </div>
            }

            {use_multi_search &&
              <div className="container">
                <form onSubmit={this.handleMultiSearch}>
                  <div className="ramka center">
                    <h4>{intl.get('Skanowanie')}</h4>
                    <input
                      type="text"
                      value={multi_search}
                      placeholder={intl.get('Kod wózka lub pakietu / Numer zamówienia lub listu przewozowego')}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKey}
                      className="searchBox"
                      id="multi_search"
                      name="multi_search"
                      autoComplete="off"
                      />
                    <div className="buttons_wrap">
                      <button className="pull-left" onClick={this.toggleMode}>{intl.get('Pokaż listę paczek')}</button>
                      <input type="submit" className="pull-right" value={intl.get('Dalej')} disabled={searching} />
                    </div>
                  </div>
                </form>
              </div>
            }
        </div>
      )
    }
  }
}

const ScanCart = connect(mapStateToProps, mapDispatchToProps)(ScanCartComponent)
export default ScanCart
